import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import { Navigation } from 'swiper/modules';
import { css } from '@emotion/react';
// import ClipLoader from 'react-spinners/ClipLoader';
import '../styles/vars.css';

// import "./styles.css";

import "semantic-ui-css/semantic.min.css"; //css file
import { Button } from 'semantic-ui-react';
import { Oval  } from  'react-loader-spinner';

// import asImage from '../assets/as.jpg';

import gql from 'graphql-tag';

const GET_IDIOMS_AND_CATEGORIES = gql`
  query GetIdiomsAndCategories {
    kategorije {
      nodes {
        id
        name
      }
    }
    idiomi (first:1000){
      nodes {
        id
        title
        kategorije {
          nodes {
            id
            name
          }
        }
        idiomiAcf {
          znacenjeIdioma
          objasnjeneIdioma
          napomena
          razgovorniIdiomiSlika {
            id
            sourceUrl
          }
        }
      }
    }
  }
`;

const override = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const getRandomColor = () => {
  const colors = [
    '#FFAC81',
    '#CA2E55',
    '#1B1B3A',
    '#8DAA9D',
    '#C5DECD',
    '#355834',
    '#7DCFB6',
    '#00B2CA',
    '#C6EBBE',
    '#7CA5B8',
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};


const shuffleArray = (array) => {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

const Slider = () => {
  const { loading, error, data } = useQuery(GET_IDIOMS_AND_CATEGORIES);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [shuffledIdioms, setShuffledIdioms] = useState([]);

  // const currentYear = new Date().getFullYear();

  useEffect(() => {
    const fetchData = async () => {
      if (!loading && data) {
        const allIdioms = data.idiomi.nodes;
        const shuffledArray = shuffleArray(allIdioms);
        setShuffledIdioms(shuffledArray);
      }
    };

    fetchData();
  }, [loading, data]);

  if (loading) {
    return (
      <div className='spinner'>
        <Oval
          height={80}
          width={80}
          color="#1b1c1d"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#1b1c1d"
          strokeWidth={3}
          strokeWidthSecondary={3}

        />
      </div>
    );
  }


  const categories = data.kategorije.nodes;

  const filteredIdioms = selectedCategoryId
    ? shuffledIdioms.filter((idiom) =>
        idiom.kategorije.nodes.some((category) => category.id === selectedCategoryId)
      )
    : shuffledIdioms;

  const handleCategoryFilter = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };



  return (



    <div className='ui container main-container'>
      <div className="izlist-main">
        <div className='all-buttons ui inverted segment'>
          <Button
          className={`ui inverted ${selectedCategoryId === null ? 'active' : ''}`}
          onClick={() => handleCategoryFilter(null)}>Vidi sve</Button>
          {categories.map((category) => (
            <Button
            className={`ui inverted ${selectedCategoryId === category.id ? 'active' : ''} ${category.name.toLowerCase()}`}
            key={category.id}
            onClick={() => handleCategoryFilter(category.id)}>
              {category.name}
            </Button>
          ))}
          </div>
      </div>

      <Swiper
        spaceBetween={60}
        slidesPerView={1}
        loop={true}
        effect={'cube'}
        grabCursor={true}
        pagination={false}
        navigation={true}
        autoHeight={true}
        modules={[Navigation]}
      >
        {filteredIdioms.map((idiom) => (
          <SwiperSlide key={idiom.id}>
          <div className={`idiom-slider-main ${idiom.idiomiAcf && idiom.idiomiAcf.razgovorniIdiomiSlika ? 'razgovorni' : ''}`}>
            <div className={`idiom-single-slide`} style={{ /* your styles here */ }}>
              <h2 className="title">{idiom.title}</h2>
              {idiom.idiomiAcf && (
                <>
                  {idiom.idiomiAcf.znacenjeIdioma && (<p className="znacenje"><b>Značenje idioma:</b> {idiom.idiomiAcf.znacenjeIdioma}</p>)}
                  {idiom.idiomiAcf.objasnjeneIdioma && (<p className="upotreba"><b>Upotreba u kontekstu:</b> {idiom.idiomiAcf.objasnjeneIdioma}</p>)}
                  {idiom.idiomiAcf.napomena && ( <p className="napomena"><b>Napomena:</b>{idiom.idiomiAcf.napomena}</p>)}
                  {idiom.idiomiAcf.razgovorniIdiomiSlika && (
                    <div className="razgovorni-idiomi-slika">
                      <img src={idiom.idiomiAcf.razgovorniIdiomiSlika.sourceUrl} alt="Razgovorni Idiomi Slika" />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </SwiperSlide>
        ))}
      </Swiper>


      {/* <div className='Merjem-slika'>
        <img src={asImage} alt="Your Alt Text" />
      </div> */}


    </div>


  );
};

export default Slider;
