// src/App.js
import React from 'react';
import Slider from './components/Slider';
import Oprojektu from './components/O-projektu';


function App() {
  return (
    <div className="">
      <Oprojektu />
    </div>
  );
}

export default App;

