import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  ViberShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  ViberIcon,
} from 'react-share';

function SocialFooter(){

  return (
<div className="social-share-buttons">
        <FacebookShareButton url={window.location.href}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={window.location.href}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <WhatsappShareButton url={window.location.href}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <ViberShareButton url={window.location.href}>
          <ViberIcon size={32} round />
        </ViberShareButton>
        <EmailShareButton url={window.location.href}>
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
  );
}

export default SocialFooter;