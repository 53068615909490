// Nav.js
import React from 'react';
import { Link } from 'react-router-dom';
import asLogoImage from '../assets/IdiomNaDanLogo.png';

function Nav() {
  return (
    <div className='mini-nav ui pointing menu'>
       <div className='Idiom-logo'>
        <a href="/"><img src={asLogoImage} alt="Idiom na Dan Logo" /></a>
      </div>
      <nav>
        {/* <Link className="item" to="/o-projektu">Početna</Link> */}
        <Link className="item" to="/knjiski-idiomi">O kњiškim idiomima</Link>
        <Link className="item" to="/razgovorni-idiomi">O razgovornim idiomima</Link>
        <Link className="item" to="/korpus">Korpus</Link>
        <Link className="item" to="/idiomi-u-pokretu">Idiomi u pokretu</Link>
        <Link className="item" to="/o-motivaciji">Bilješka o blogu</Link>
        <Link className="item" to="/o-autorici">Bilješka o autorici</Link>
      </nav>
    </div>
  );
}

export default Nav;
