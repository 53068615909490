// src/App.js
import React from 'react';
import Slider from './Slider';


function Korpus() {
  return (
    <div className="App reusable-class">
      <Slider />
    </div>
  );
}

export default Korpus;