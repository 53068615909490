const currentYear = new Date().getFullYear();

function Footer(){

  return (



    <div className="footer copyright">

    <span>&copy; {currentYear} <a href="https://www.instagram.com/azrabla/?hl=en" target='_blank'>Azra Hodžić-Čavkić</a> | Made by <a href="https://vahidhodzic.github.io/" target="_blank">Vahid Hodžić</a> |  ISSN 3029-3162</span>
  </div>
  );
}

export default Footer;
