// Pocetna.js
import React from 'react';
import { useQuery, gql } from '@apollo/client';



const GET_PAGE_CONTENT = gql`
  query GetPageContent {
    page(idType: URI, id: "/pocetna/") {
      title
      isPostsPage
      content
    }
  }
`;

function O_projektu() {
  const { loading, error, data } = useQuery(GET_PAGE_CONTENT);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { title, content } = data.page;

  return (
    <div className='O-projektu reusable-class'>
      {/* <h1>{title}</h1> */}

      <div className='wp-pages ui container main-container' dangerouslySetInnerHTML={{ __html: content }} />

    </div>
  );

}

export default O_projektu;
